<template>
  <div class="custom-select">
    <div
      class="cost-select el-textarea el-input--small"
      :class="{ 'disabled-select': disabled }"
      style="min-height: 32px"
      @click="openModal"
    >
      <el-tag
        v-for="(item, index) in value"
        :key="index"
        :closable="!disabled"
        @close="handleClose(item)"
        >{{ item.orgName }}</el-tag
      >
    </div>
    <!-- 弹框 -->
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
  </div>
</template>

<script>
// import request from '../../../../utils/request';
import SelectConfig from '@/found/components/select_org_cus_ter/select_config.vue';

export default {
  name: 'OrgList',
  props: {
    autosize: Object,
    title: String,
    value: Array,
    disabled: {
      type: Boolean,
      default: () => false,
    },
    getValue: Function,
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  components: {
    SelectConfig,
  },

  methods: {
    // 新增弹窗
    openModal() {
      const selectionList = [];
      console.log('🚀 ~ this.value.forEach ~ this.value:', this.value);
      if (this.value) {
        this.value.forEach((item) => {
          selectionList.push(item);
        });
      }
      console.log('🚀 ~ this.value.forEach ~ selectionList:', selectionList);
      let params = {
        ...this.params,
        paramData: {
          enableStatus: '009',
        },
      };

      params = {
        ...params,
        idKey: 'orgCode',
        data: selectionList,
        noReset: true,
      };
      this.$refs.selectConfig.openSelectModal(params);
    },
    // 判断去重
    getReset(list1, list2, key) {
      const list = list2;
      if (list1 && list1.length) {
        list1.forEach((v1) => {
          let notRepeat = true;
          const rowData = v1;
          for (const v2 in list2) {
            if (v1[key] === list2[v2][key]) {
              notRepeat = true;
              return false;
            }
          }
          if (notRepeat) {
            list.push(rowData);
          }
          return rowData;
        });
      }
      return list;
    },
    // 选择回调
    onGetSelect(val) {
      console.log('🚀 ~ onGetSelect ~ val:', val);
      const data = this.getReset(val, [], 'orgCode');
      this.$emit('change', data);
    },
    // 清除选择
    clearFn(e) {
      e.stopPropagation();
      this.$emit('change');
    },
    handleClose(item) {
      if (this.disabled) {
        return;
      }
      const data = this.value.filter((v) => v.orgCode !== item.orgCode);
      this.$emit('input', data);
    },
  },
};
</script>

<style lang="less" scoped>
.cost-select {
  position: relative;
  border: 1px solid #dddfe6;
  border-radius: 5px;
  padding: 5px;

  /deep/ .el-input__inner {
    padding-right: 30px;
  }

  .clear-icon {
    width: 25px;
    height: 100%;
    position: absolute;
    right: 5px;
    top: 0;
    text-align: center;
    color: #c0c4cc;
    transition: all 0.3s;
    padding-top: 1px;
    display: none;

    .el-icon-circle-close {
      width: 100%;
      font-size: 14px;
      cursor: pointer;
      transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }

  &:hover {
    .clear-icon {
      display: block;
    }
  }
}
.disabled-select {
  pointer-events: none;
  background: #f5f7fa;
}

::v-deep {
  .scroll-wrap {
    height: 100%;
    margin-right: -17px;
    padding-right: 17px;
    opacity: 0;
  }
  .el-scrollbar__view {
    overflow: auto !important;
  }
  .el-scrollbar__bar.is-horizontal {
    height: 17px;
    margin-top: -17px;
  }
  .el-scrollbar__thumb.is-horizontal {
    height: 100%;
  }
  .el-scrollbar__bar.is-vertical {
    width: 17px;
    margin-left: -17px;
  }
  .el-scrollbar__thumb.is-vertical {
    width: 100%;
    border-radius: 0;
  }
}
</style>
