<script>
import Form from '@/found/components/form';
import request from '@/found/utils/request';
import BusinessOrganization from './components/business_organization.vue';
import PositionLevelList from './components/position_level_list.vue';

formCreate.component('BusinessOrganization', BusinessOrganization);
formCreate.component('PositionLevelList', PositionLevelList);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      row: null,
      formFunctionCode: 'knowledge_base_form',
    };
  },
  mounted() {
    // if (this.formConfig.type === 'edit' || this.formConfig.type === 'view') {
    //   request
    //     .get('/mdm/knowledgeBase/queryById', {
    //       id: this.formConfig.id,
    //     })
    //     .then((res) => {
    //       if (res.success) {
    //         this.row = res.result;
    //         this.setValue(res.result);
    //       }
    //     });
    // }
  },
  methods: {
    // 设置规则
    setRule(item) {
      if (item.field === 'orgVoList') {
        console.log(11);

        item.props = {
          ...item.props,
          params: {
            functionCode: 'business_organization_list',
          },
        };
        item.on = {
          ...item.on,
          change: (e) => {
            console.log('🚀 ~ setRule ~ e:', e);
            const arr = [...e];
            const orgVos = [];
            arr.forEach((m) => {
              orgVos.push({
                orgType: m.orgType,
                orgName: m.orgName,
                orgCode: m.orgCode,
              });
            });
            this.setValue({
              orgVoList: orgVos || [],
            });
          },
        };
      } else if (item.field === 'positionVoList') {
        item.props = {
          ...item.props,
          params: {
            functionCode: 'PositionLevelList',
          },
        };
        item.on = {
          ...item.on,
          change: (e) => {
            console.log('🚀 ~ setRule ~ e:', e);
            const arr = [...e];

            this.setValue({
              positionVoList: arr || [],
            });
          },
        };
      } else if (item.field === 'filesVoList') {
        item.props.showFileList = true;
        item.props.uploadType = 'file';
        item.props.listType = 'text';
        item.props.fileMsg = '只能上传pdf/word/excel/ppt/png/jpg文件';
        item.props.accept = '.pdf,.doc,.docx,.xlsx,.xls,.ppt,.pptx,.png,.jpg';
        if (this.formConfig.code === 'view') {
          item.props.disabled = true;
        }
      }

      return item;
    },

    // 提交
    submit() {
      const formData = this.getFormData();
      if (formData) {
        console.log('🚀 ~ submit ~ formData:', formData);
        let url = '/mdm/knowledgeBase/upload';
        const params = { ...formData };
        if (this.formConfig.type === 'edit') {
          url = '/mdm/knowledgeBase/edit';
          // params = Object.assign(this.row, formData);
          params.id = this.row.id;
        }
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },

    formComplete() {
      if (this.formConfig.type === 'edit' || this.formConfig.type === 'view') {
        request
          .get('/mdm/knowledgeBase/queryById', {
            id: this.formConfig.id,
          })
          .then((res) => {
            if (res.success) {
              this.row = res.result;
              this.setValue(res.result);
            }
          });
      }
    },
  },
};
</script>
