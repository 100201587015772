<script>
import request, { portUrl } from '@/found/utils/request';
import TablePage from '../../../../components/table_page';
import Modal from '../../../../components/modal';
import Form from './form';

export default {
  name: 'knowledgeBase',
  extends: TablePage,
  data() {
    return {
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  components: {
    Modal,
    Form,
  },

  methods: {
    // 按钮点击事件
    modalClick({ val, row }) {
      if (val.code === 'upload') {
        this.modalConfig.title = '上传';
        this.modalConfig.height = '360px';
        this.modalConfig.width = '1000px';
        this.openFull();
      } else if (val.code === 'edit') {
        this.modalConfig.title = '编辑';
        this.modalConfig.height = '360px';
        this.modalConfig.width = '1000px';
        this.openFull();
      } else if (val.code === 'view') {
        this.modalConfig.title = '查看';
        this.modalConfig.height = '360px';
        this.modalConfig.width = '1000px';
        this.formConfig.buttonCode = 'view';
        this.openFull();
      } else if (val.code === 'download') {
        this.handleBatchDown(row.filesVoList);
      }
      this.formConfig = {
        type: val.code,
        code: val.code,
        id: row.id || null,
      };
    },

    // 批量下载
    handleBatchDown(arr) {
      const dataSource = arr;
      dataSource.forEach((item) => {
        const iframe = document.createElement('iframe'); //  先创建一个iframe 标签
        iframe.style.display = 'none'; // 不能在页面中被看到，把他隐藏起来，不影响我们使用啊
        iframe.style.height = '0px'; // 给他个0的高度，免得影响页面布局
        iframe.src = `${
          portUrl || ''
        }upload/downloadController/downloadExcelFile?objectName=${
          item.objectName
        }`; // 我们的下载地址
        document.body.appendChild(iframe);
        setTimeout(() => {
          iframe.remove();
        }, 10000);
      });
    },
  },
  created() {
    // 重写获取配置信息
    this.getConfigList('knowledge_base_list');
  },
};
</script>
